import React, { useState, useEffect } from 'react'
import Maps from './Maps'

const Booklet = () => {
  const [darkMode, setDarkMode] = useState(() => {
    const saved = localStorage.getItem('darkMode')
    return saved ? JSON.parse(saved) : false
  })
  const [activeWeeks, setActiveWeeks] = useState([])
  const [activeDay, setActiveDay] = useState(() => {
    const saved = localStorage.getItem('activeDay')
    if (saved) {
      return parseInt(saved)
    }
    // Get today's date and calculate days since Jan 1, 2025
    const today = new Date()
    const start = new Date(2025, 0, 1)
    const diffTime = today.getTime() - start.getTime()
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
    return diffDays
  })
  const [markerCounts, setMarkerCounts] = useState({})
  const [showNotes, setShowNotes] = useState(() => {
    const saved = localStorage.getItem('showNotes')
    return saved ? JSON.parse(saved) : false
  })
  const [generalNotes, setGeneralNotes] = useState(() => {
    const saved = localStorage.getItem('generalNotes')
    return saved || ''
  })
  const [savedNotes, setSavedNotes] = useState(() => {
    const saved = localStorage.getItem('savedNotes')
    const notes = saved ? JSON.parse(saved) : []
    // Sort by lastEdited timestamp, fallback to id (creation time) if no lastEdited
    return notes.sort((a, b) => (b.lastEdited || b.id) - (a.lastEdited || a.id))
  })
  const [activeNoteIndex, setActiveNoteIndex] = useState(null)
  const [activeMarker, setActiveMarker] = useState(null)

  // Set initial active note on load
  useEffect(() => {
    if (savedNotes.length > 0 && activeNoteIndex === null) {
      setActiveNoteIndex(0)
      setGeneralNotes(savedNotes[0].content)
    }
  }, [savedNotes])

  // Save showNotes state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('showNotes', JSON.stringify(showNotes))
  }, [showNotes])

  const local = {
    page: {
      display: 'flex',
      flexDirection: 'row',
      height: '100vh',
      width: '100%',
      position: 'relative'
    },
    sidebar: {
      width: 300,
      borderRight: `1px solid ${darkMode ? '#333' : '#eee'}`,
      overflowY: 'auto',
      padding: '2px',
      backgroundColor: darkMode ? '#222' : '#fff',
      color: darkMode ? '#fff' : '#000'
    },
    calendar: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 2,
      width: '100%'
    },
    dateBox: {
      padding: '2px',
      border: `1px solid ${darkMode ? '#333' : '#eee'}`,
      borderRadius: '4px',
      backgroundColor: darkMode ? '#333' : '#fbfbfb',
      width: 'calc(14.28% - 2px)',
      aspectRatio: '1',
      boxSizing: 'border-box',
      textAlign: 'center',
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: darkMode ? '#fff' : '#000',
      cursor: 'pointer',
      position: 'relative'
    },
    markerCount: {
      position: 'absolute',
      top: 2,
      right: 2,
      backgroundColor: darkMode ? '#4a5568' : '#e2e8f0',
      borderRadius: '50%',
      width: 16,
      height: 16,
      fontSize: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    firstOfMonth: {
      backgroundColor: darkMode ? '#1a365d' : '#e6f3ff',
    },
    activeWeek: {
      backgroundColor: darkMode ? '#2a4365' : '#f8eae4',
    },
    activeDay: {
      border: `2px solid ${darkMode ? '#fff' : '#000'}`,
    },
    content: {
      flex: 1,
      backgroundColor: darkMode ? '#111' : '#fff'
    },
    controls: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '8px',
      alignItems: 'center'
    },
    weekDays: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-around',
      padding: '8px 0',
      borderBottom: `1px solid ${darkMode ? '#333' : '#eee'}`,
      fontSize: 12
    },
    notesToggle: {
      position: 'absolute',
      top: 10,
      right: 10,
      width: 40,
      height: 40,
      backgroundColor: darkMode ? '#333' : '#fff',
      border: `1px solid ${darkMode ? '#555' : '#ddd'}`,
      borderRadius: 4,
      cursor: 'pointer',
      zIndex: 1000,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    notesPanel: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: 600,
      height: '100%',
      backgroundColor: darkMode ? '#222' : '#fff',
      borderLeft: `1px solid ${darkMode ? '#333' : '#ddd'}`,
      transition: 'transform 0.3s ease-in-out',
      transform: showNotes ? 'translateX(0)' : 'translateX(100%)',
      zIndex: 999,
      padding: 2,
      boxSizing: 'border-box',
      display: 'flex'
    },
    notesTextarea: {
      width: '100%',
      height: 'calc(100% - 40px)',
      backgroundColor: darkMode ? '#333' : '#fff',
      color: darkMode ? '#fff' : '#000',
      border: `0px solid ${darkMode ? '#555' : '#ddd'}`,
      borderRadius: 4,
      padding: 2,
      resize: 'none',
      fontSize: 14,
      marginTop: 20
    },
    notesEditor: {
      width: 400,
      height: '100%',
      borderRight: `1px solid ${darkMode ? '#333' : '#ddd'}`,
      padding: '10px',
      position: 'relative'
    },
    deleteButton: {
      position: 'absolute',
      top: 10,
      right: 10,
      padding: '4px 8px',
      backgroundColor: darkMode ? '#444' : '#e0e0e0',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      color: darkMode ? '#fff' : '#000',
      zIndex: 1
    },
    notesArchive: {
      width: 150,
      height: '100%',
      padding: '10px',
      overflowY: 'auto'
    },
    archiveNote: {
      padding: '10px',
      marginBottom: '10px',
      backgroundColor: darkMode ? '#333' : '#f0f0f0',
      borderRadius: '4px',
      cursor: 'pointer',
      maxHeight: '100px',
      overflow: 'hidden'
    },
    archiveNoteActive: {
      backgroundColor: darkMode ? '#444' : '#e0e0e0',
      border: `1px solid ${darkMode ? '#555' : '#ccc'}`
    },
    newNoteButton: {
      width: '100%',
      padding: '10px',
      marginBottom: '10px',
      backgroundColor: darkMode ? '#444' : '#e0e0e0',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      color: darkMode ? '#fff' : '#000'
    }
  }

  const handleNewNote = () => {
    const newNote = {
      id: Date.now(),
      content: '',
      date: new Date().toLocaleDateString(),
      lastEdited: Date.now()
    }
    const updatedNotes = [newNote, ...savedNotes]
    setSavedNotes(updatedNotes)
    setActiveNoteIndex(0)
    setGeneralNotes('')
    localStorage.setItem('savedNotes', JSON.stringify(updatedNotes))
  }

  const handleNotesChange = (e) => {
    const newNotes = e.target.value
    setGeneralNotes(newNotes)
    if (activeNoteIndex !== null) {
      const updatedNotes = [...savedNotes]
      updatedNotes[activeNoteIndex] = {
        ...updatedNotes[activeNoteIndex],
        content: newNotes,
        lastEdited: Date.now()
      }
      // Re-sort notes by lastEdited
      const sortedNotes = updatedNotes.sort((a, b) => b.lastEdited - a.lastEdited)
      // Find new index of the active note after sorting
      const newActiveIndex = sortedNotes.findIndex(note => note.id === updatedNotes[activeNoteIndex].id)
      setSavedNotes(sortedNotes)
      setActiveNoteIndex(newActiveIndex)
      localStorage.setItem('savedNotes', JSON.stringify(sortedNotes))
    }
  }

  const handleDeleteNote = () => {
    if (activeNoteIndex !== null) {
      const updatedNotes = savedNotes.filter((_, index) => index !== activeNoteIndex)
      setSavedNotes(updatedNotes)
      localStorage.setItem('savedNotes', JSON.stringify(updatedNotes))
      
      if (updatedNotes.length > 0) {
        setActiveNoteIndex(0)
        setGeneralNotes(updatedNotes[0].content)
      } else {
        setActiveNoteIndex(null)
        setGeneralNotes('')
      }
    }
  }

  const handleArchiveNoteClick = (index) => {
    setActiveNoteIndex(index)
    setGeneralNotes(savedNotes[index].content)
  }

  const handleMarkerDelete = (markerId) => {
    // This function will be passed to Maps component
    setMarkerCounts(prev => {
      const newCounts = {...prev}
      if (newCounts[activeDay] > 0) {
        newCounts[activeDay]--
      }
      return newCounts
    })
  }

  // Rest of the functions remain the same...
  const getDates = () => {
    const dates = []
    const startDate = new Date(2025, 0, 1)
    const endDate = new Date(2025, 11, 31)
    
    let currentDate = new Date(startDate)
    
    const firstDay = startDate.getDay()
    for (let i = firstDay; i > 0; i--) {
      const prevDate = new Date(startDate)
      prevDate.setDate(prevDate.getDate() - i)
      dates.push({
        date: prevDate.getDate(),
        month: prevDate.toLocaleDateString('en-US', { month: 'short' }),
        isFirstOfMonth: prevDate.getDate() === 1
      })
    }
    
    while (currentDate <= endDate) {
      dates.push({
        date: currentDate.getDate(),
        month: currentDate.toLocaleDateString('en-US', { month: 'short' }),
        isFirstOfMonth: currentDate.getDate() === 1
      })
      currentDate.setDate(currentDate.getDate() + 1)
    }

    const lastDay = endDate.getDay()
    for (let i = 1; i < 7 - lastDay; i++) {
      const nextDate = new Date(endDate)
      nextDate.setDate(nextDate.getDate() + i)
      dates.push({
        date: nextDate.getDate(),
        month: nextDate.toLocaleDateString('en-US', { month: 'short' }),
        isFirstOfMonth: nextDate.getDate() === 1
      })
    }
    
    return dates
  }

  const handleDateClick = (index) => {
    const weekNumber = Math.floor(index / 7)
    
    if (activeDay === index) {
      setActiveWeeks(prevWeeks => {
        if (prevWeeks.includes(weekNumber)) {
          return prevWeeks.filter(week => week !== weekNumber)
        }
        return [...prevWeeks, weekNumber]
      })
    } else {
      setActiveDay(index)
      localStorage.setItem('activeDay', index.toString())
    }
  }

  const handleMarkerAdded = (dayIndex) => {
    setMarkerCounts(prev => ({
      ...prev,
      [dayIndex]: (prev[dayIndex] || 0) + 1
    }))
  }

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode
    setDarkMode(newDarkMode)
    localStorage.setItem('darkMode', JSON.stringify(newDarkMode))
  }

  const handleMapClick = () => {
    /*
    if (showNotes) {
      setShowNotes(false)
    }
    */
  }

  return (
    <div style={local.page}>
      <div style={local.sidebar}>
        <div style={local.controls}>
          <button onClick={toggleDarkMode}>{darkMode ? '☀️' : '🌙'}</button>
          <button>⚙️</button>
        </div>
        <div style={local.weekDays}>
          <span>Sun</span>
          <span>Mon</span>
          <span>Tue</span>
          <span>Wed</span>
          <span>Thu</span>
          <span>Fri</span>
          <span>Sat</span>
        </div>
        <div style={local.calendar}>
          {getDates().map((dateObj, i) => {
            const weekNumber = Math.floor(i / 7)
            const isActiveWeek = activeWeeks.includes(weekNumber)
            const isActiveDay = activeDay === i
            const markerCount = markerCounts[i]
            return (
              <div 
                key={i} 
                style={{
                  ...local.dateBox,
                  ...(isActiveWeek && !dateObj.isFirstOfMonth ? local.activeWeek : {}),
                  ...(dateObj.isFirstOfMonth ? local.firstOfMonth : {}),
                  ...(isActiveDay ? local.activeDay : {})
                }}
                onClick={() => handleDateClick(i)}
              >
                {dateObj.isFirstOfMonth ? `${dateObj.month} ${dateObj.date}` : dateObj.date}
                {markerCount > 0 && (
                  <div style={local.markerCount}>{markerCount}</div>
                )}
              </div>
            )
          })}
        </div>
      </div>
      <div style={local.content} onClick={handleMapClick}>
        <div style={{ height: '100%'}}>
          <Maps 
            darkMode={darkMode} 
            activeWeeks={activeWeeks} 
            activeDay={activeDay}
            onMarkerAdded={handleMarkerAdded}
            handleMarkerDelete={handleMarkerDelete}
            activeMarker={activeMarker}
            setActiveMarker={setActiveMarker}
          />
        </div>
      </div>
      <button 
        style={local.notesToggle}
        onClick={() => setShowNotes(!showNotes)}
      >
        📝
      </button>
      <div style={local.notesPanel}>
        <div style={local.notesEditor}>
          {activeNoteIndex !== null && (
            <button 
              style={local.deleteButton}
              onClick={handleDeleteNote}
            >
              ✕
            </button>
          )}
          <textarea
            value={generalNotes}
            onChange={handleNotesChange}
            style={local.notesTextarea}
            placeholder="Add note here..."
          />
        </div>
        <div style={local.notesArchive}>
          <button 
            style={local.newNoteButton}
            onClick={handleNewNote}
          >
            + New Note
          </button>
          {savedNotes.map((note, index) => (
            <div
              key={note.id}
              style={{
                ...local.archiveNote,
                ...(activeNoteIndex === index ? local.archiveNoteActive : {})
              }}
              onClick={() => handleArchiveNoteClick(index)}
            >
              <div style={{fontSize: '12px', marginBottom: '5px'}}>{note.date}</div>
              <div style={{fontSize: '14px'}}>{note.content.substring(0, 100)}...</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Booklet
