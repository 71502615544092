import 'leaflet/dist/leaflet.css'
import React, {useEffect, useState} from 'react'
import {MapContainer, TileLayer, Marker, Popup, useMapEvents, Polyline} from 'react-leaflet'
import L from 'leaflet'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'

const MapEvents = ({ onRightClick, onMoveEnd }) => {
  const map = useMapEvents({
    contextmenu: (e) => {
      onRightClick(e.latlng)
    },
    moveend: () => {
      const center = map.getCenter()
      const zoom = map.getZoom()
      onMoveEnd({center: [center.lat, center.lng], zoom})
    }
  })
  return null
}

const MapComponent = ({ darkMode, activeDay, activeWeeks, onMarkerAdded, handleMarkerDelete, activeMarker, setActiveMarker }) => {
  const [mapState, setMapState] = useState(() => {
    const saved = localStorage.getItem('mapState')
    return saved ? JSON.parse(saved) : {
      center: [51.505, -0.09], // Default coordinates
      zoom: 4
    }
  })
  const [markersByDay, setMarkersByDay] = useState(() => {
    const saved = localStorage.getItem('markersByDay')
    return saved ? JSON.parse(saved) : {}
  })
  const [markerNotes, setMarkerNotes] = useState(() => {
    const saved = localStorage.getItem('markerNotes')
    return saved ? JSON.parse(saved) : {}
  })
  const [markerColors, setMarkerColors] = useState(() => {
    const saved = localStorage.getItem('markerColors')
    return saved ? JSON.parse(saved) : {}
  })
  const [selectedMarkers, setSelectedMarkers] = useState([])

  useEffect(() => {
    // Fix for default marker icon
    let DefaultIcon = L.icon({
      iconUrl: icon,
      shadowUrl: iconShadow,
      iconSize: [25, 41],
      iconAnchor: [12, 41]
    });
    L.Marker.prototype.options.icon = DefaultIcon;
  }, [])

  // Save markers to localStorage and update counts only when markers change
  useEffect(() => {
    localStorage.setItem('markersByDay', JSON.stringify(markersByDay))
  }, [markersByDay])

  // Update marker counts on initial load
  useEffect(() => {
    Object.entries(markersByDay).forEach(([dayIndex, markers]) => {
      // Call onMarkerAdded for each marker in the day
      for (let i = 0; i < markers.length; i++) {
        onMarkerAdded(parseInt(dayIndex))
      }
    })
  }, []) // Empty dependency array means this only runs once on mount

  useEffect(() => {
    localStorage.setItem('markerNotes', JSON.stringify(markerNotes))
  }, [markerNotes])

  useEffect(() => {
    localStorage.setItem('markerColors', JSON.stringify(markerColors))
  }, [markerColors])

  useEffect(() => {
    localStorage.setItem('mapState', JSON.stringify(mapState))
  }, [mapState])

  const handleRightClick = (latlng) => {
    if (activeDay === null) return // Don't add markers if no day is selected

    const newMarker = {
      id: Date.now(),
      position: [latlng.lat, latlng.lng],
      color: '#3388ff' // Default color
    }

    setMarkersByDay(prev => {
      const updatedMarkers = {
        ...prev,
        [activeDay]: [...(prev[activeDay] || []), newMarker]
      }
      // Call onMarkerAdded only when adding a new marker
      onMarkerAdded(activeDay)
      return updatedMarkers
    })
    setActiveMarker(newMarker.id)
  }

  const handleNoteChange = (markerId, note) => {
    setMarkerNotes({
      ...markerNotes,
      [markerId]: note
    })
  }

  const handleColorChange = (markerId, color) => {
    setMarkerColors({
      ...markerColors,
      [markerId]: color
    })
  }

  const handleDeleteMarker = (markerId) => {
    // Find which day contains this marker
    let markerDay = null;
    Object.entries(markersByDay).forEach(([day, markers]) => {
      if (markers.some(m => m.id === markerId)) {
        markerDay = parseInt(day);
      }
    });

    if (markerDay !== null) {
      setMarkersByDay(prev => {
        const updatedMarkers = {
          ...prev,
          [markerDay]: prev[markerDay].filter(m => m.id !== markerId)
        };
        return updatedMarkers;
      });

      // Clean up associated data
      const newMarkerNotes = {...markerNotes};
      delete newMarkerNotes[markerId];
      setMarkerNotes(newMarkerNotes);

      const newMarkerColors = {...markerColors};
      delete newMarkerColors[markerId];
      setMarkerColors(newMarkerColors);

      handleMarkerDelete(markerDay);
      setActiveMarker(null);
      setSelectedMarkers(selected => selected.filter(id => id !== markerId));
    }
  }

  const handleMapMove = (newMapState) => {
    setMapState(newMapState)
  }

  const handleMarkerSelect = (markerId) => {
    setSelectedMarkers(selected => {
      if (selected.includes(markerId)) {
        return selected.filter(id => id !== markerId);
      }
      if (selected.length >= 2) {
        return [selected[1], markerId];
      }
      return [...selected, markerId];
    });
    setActiveMarker(markerId);
  }

  const calculateDistance = (pos1, pos2) => {
    // Using Haversine formula to calculate distance
    const R = 3959; // Earth's radius in miles
    const lat1 = pos1[0] * Math.PI / 180;
    const lat2 = pos2[0] * Math.PI / 180;
    const dLat = (pos2[0] - pos1[0]) * Math.PI / 180;
    const dLon = (pos2[1] - pos1[1]) * Math.PI / 180;

    const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
              Math.cos(lat1) * Math.cos(lat2) *
              Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const distance = R * c;
    return distance.toFixed(2);
  }

  const calculateDriveTime = (distance) => {
    const speedMph = 70; // Average speed in mph
    const hours = distance / speedMph;
    const days = Math.floor(hours / 24);
    const remainingHours = Math.floor(hours % 24);
    const minutes = Math.round((hours % 1) * 60);

    let timeString = '';
    if (days > 0) {
      timeString += `${days} day${days > 1 ? 's' : ''} `;
    }
    if (remainingHours > 0) {
      timeString += `${remainingHours} hour${remainingHours > 1 ? 's' : ''} `;
    }
    if (minutes > 0) {
      timeString += `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
    return timeString.trim();
  }

  // Get markers for active day and active weeks
  const visibleMarkers = []
  if (activeDay !== null) {
    const dayMarkers = markersByDay[activeDay] || []
    visibleMarkers.push(...dayMarkers)
  }
  
  // Add markers from active weeks
  activeWeeks.forEach(weekNumber => {
    const startDay = weekNumber * 7
    const endDay = startDay + 6
    for (let i = startDay; i <= endDay; i++) {
      if (i !== activeDay && markersByDay[i]) { // Skip active day as it's already included
        visibleMarkers.push(...markersByDay[i])
      }
    }
  })

  // Get selected marker positions for the line
  const selectedPositions = selectedMarkers
    .map(id => visibleMarkers.find(m => m.id === id)?.position)
    .filter(pos => pos !== undefined);

  return (
    <MapContainer 
      center={mapState.center}
      zoom={mapState.zoom}
      zoomControl={false} // This disables the zoom controls
      style={{
        height: '100vh', 
        width: '100%',
        cursor: 'grab',
        backgroundColor: darkMode ? '#111' : '#fff' // Add background color based on theme
      }}
    >
      <MapEvents onRightClick={handleRightClick} onMoveEnd={handleMapMove} />
      <TileLayer
        url={darkMode 
          ? "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
          : "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        }
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      />
      {selectedPositions.length === 2 && (
        <Polyline 
          positions={selectedPositions}
          color={darkMode ? "#fff" : "#000"}
          dashArray="5, 10"
        />
      )}
      {visibleMarkers.map((marker) => (
        <Marker 
          key={marker.id} 
          position={marker.position}
          icon={L.divIcon({
            className: 'custom-icon',
            html: `<div style="
              background-color: ${markerColors[marker.id] || marker.color}; 
              width: 20px; 
              height: 20px; 
              clip-path: polygon(50% 100%, 0% 0%, 100% 0%); 
              border: 2px solid ${selectedMarkers.includes(marker.id) ? '#ff0' : 'black'};
              box-sizing: border-box;
              cursor: crosshair;
            "></div>`
          })}
          eventHandlers={{
            click: () => handleMarkerSelect(marker.id)
          }}
        >
          <Popup>
            <div>
              Lat: {marker.position[0].toFixed(4)}, Lng: {marker.position[1].toFixed(4)}
              {selectedMarkers.length === 2 && selectedMarkers.includes(marker.id) && (
                <div style={{marginTop: '8px', fontWeight: 'bold'}}>
                  <div>Distance: {calculateDistance(
                    marker.position,
                    visibleMarkers.find(m => m.id === selectedMarkers.find(id => id !== marker.id))?.position
                  )} miles</div>
                  <div>Est. drive time at 70mph: {calculateDriveTime(calculateDistance(
                    marker.position,
                    visibleMarkers.find(m => m.id === selectedMarkers.find(id => id !== marker.id))?.position
                  ))}</div>
                </div>
              )}
              <br />
              <textarea
                autoFocus={marker.id === activeMarker}
                value={markerNotes[marker.id] || ''}
                onChange={(e) => handleNoteChange(marker.id, e.target.value)}
                placeholder="Add a note..."
                style={{
                  width: '200px',
                  height: '100px',
                  margin: '8px 0'
                }}
              />
              <br />
              <input
                type="color"
                value={markerColors[marker.id] || marker.color}
                onChange={(e) => handleColorChange(marker.id, e.target.value)}
              />
              <button 
                onClick={() => handleDeleteMarker(marker.id)}
                style={{
                  marginLeft: '8px',
                  padding: '4px 8px',
                  backgroundColor: '#fff',
                  color: '#444',
                  border:'1px solid #444',
                  borderRadius: '4px',
                  cursor: 'pointer'
                }}
              >
                Delete
              </button>
            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  )
}

export default MapComponent
